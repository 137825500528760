import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CreditMemoFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    amountFrom: [],
    amountTo: [],
    customer: [],
    dateFrom: [],
    dateTo: [],
    memo: [],
    refNo: [],
    remainingFrom: [],
    remainingTo: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.amountFrom) filters.subtotalFrom = Number(values.amountFrom);
      if (values.amountTo) filters.subtotalTo = Number(values.amountTo);
      if (values.customer) filters.customerName = values.customer;
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.memo) filters.memo = values.memo;
      if (values.refNo) filters.referenceNumber = values.refNo;
      if (values.remainingFrom)
        filters.creditRemainingFrom = Number(values.remainingFrom);
      if (values.remainingTo)
        filters.creditRemainingTo = Number(values.remainingTo);

      return filters;
    })
  );

  amountFrom$ = this.form.get("amountFrom")?.valueChanges;
  amountTo$ = this.form.get("amountTo")?.valueChanges;
  customer$ = this.form.get("customer")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;
  refNo$ = this.form.get("refNo")?.valueChanges;
  remainingFrom$ = this.form.get("remainingFrom")?.valueChanges;
  remainingTo$ = this.form.get("remainingTo")?.valueChanges;

  reset() {
    this.form.patchValue({
      amountFrom: null,
      amountTo: null,
      customer: null,
      dateFrom: null,
      dateTo: null,
      memo: null,
      refNo: null,
      remainingFrom: null,
      remainingTo: null,
    });
  }
}
