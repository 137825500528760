import { Injectable, computed, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  TASK_DEFAULT_STATUS,
  TASK_ACTIVITY_OPTIONS,
  TASK_DUE_OPTIONS,
} from "./task.constants";
import { TaskOption } from "./task.model";
import { TaskService } from "./task.service";
import { map, merge } from "rxjs";
import { UserConfigStore } from "@app/core/user-config/+data-access";

@Injectable({ providedIn: "root" })
export class TaskOptionStore {
  #options = signal<TaskOption>({
    activities: TASK_ACTIVITY_OPTIONS,
    due: TASK_DUE_OPTIONS,
    assignedUsers: [],
    associations: [],
    types: [],
    statuses: TASK_DEFAULT_STATUS.map(x => ({
      // NOTE: set id to string to support our multiselect dropdown;
      // later will be convert back to integer. see TaskService.getTaskStatuses()
      key: `${x.id}`,
      value: x.name,
    })),
  });

  readonly options = this.#options.asReadonly();

  readonly dropdownConfigs = computed(() => {
    const all = this.options();
    const taskType = {
      options: all.types,
      title: "Task Types",
    };
    const taskAssignee = {
      options: all.assignedUsers,
      title: "Assignee",
    };
    const taskStatus = {
      options: all.statuses,
      title: "Status",
    };
    const taskActivities = {
      options: all.activities,
      title: "Activities",
    };
    const taskDue = {
      options: all.due,
      title: "Due Date",
    };
    return {
      taskActivities,
      taskAssignee,
      taskStatus,
      taskType,
      taskDue,
    };
  });

  constructor(
    protected taskService: TaskService,
    protected userConfig: UserConfigStore
  ) {
    const taskTypes$ = taskService.getTaskTypes().pipe(
      map(resp => {
        const types: TaskOption["types"] =
          resp?.taskTypes.map(x => ({ key: x.id, value: x.name })) || [];
        return { types };
      })
    );
    const assignedUsers$ = taskService.getAssignedusers().pipe(
      map(resp => {
        const options: TaskOption["assignedUsers"] = [];
        for (const user of resp?.data || []) {
          options.push({
            key: user.id,
            value: user,
            label: user.name,
          });
        }
        return { assignedUsers: options };
      })
    );
    const associations$ = userConfig.associations$.pipe(
      map(list => {
        const associations: TaskOption["associations"] = [];
        for (const item of list || []) {
          associations.push({
            key: item.id,
            value: item,
            label: item.name,
          });
        }
        return { associations };
      })
    );
    merge(taskTypes$, assignedUsers$, associations$)
      .pipe(takeUntilDestroyed())
      .subscribe(result => {
        this.#options.update(state => ({
          ...state,
          ...result,
        }));
      });
  }
}
