import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class UnitFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    address: [[]],
    association: [[]],
    balanceTo: [],
    balanceFrom: [],
    customer: [[]],
  });

  address$ = this.form.get("address")?.valueChanges;
  addressOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  association$ = this.form.get("association")?.valueChanges;
  associationOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  balanceTo$ = this.form.get("balanceMax")?.valueChanges;
  balanceFrom$ = this.form.get("balanceMin")?.valueChanges;

  customer$ = this.form.get("customer")?.valueChanges;
  customerOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  constructor() {
    /* TODO | aq | remove this debug */
    this.form.valueChanges.subscribe(value => console.log(value));

    this.getOptions();
  }

  private getOptions() {
    /* TODO | aq | integrate with API */
    setTimeout(() => {
      this.addressOptions$.next([
        { label: "1311 Adcox Square", value: "1311 Adcox Square" },
        { label: "1312 Adcox Square", value: "1312 Adcox Square" },
        { label: "1313 Adcox Square", value: "1313 Adcox Square" },
        { label: "1314 Adcox Square", value: "1314 Adcox Square" },
        { label: "1315 Adcox Square", value: "1315 Adcox Square" },
        { label: "1311 Annslee Circle", value: "1311 Annslee Circle" },
        { label: "1312 Annslee Circle", value: "1312 Annslee Circle" },
        { label: "1313 Annslee Circle", value: "1313 Annslee Circle" },
        { label: "1314 Annslee Circle", value: "1314 Annslee Circle" },
      ]);
      this.associationOptions$.next([
        { label: "Association A", value: "Association A" },
        { label: "Association B", value: "Association B" },
        { label: "Association C", value: "Association C" },
      ]);
      this.customerOptions$.next([
        { label: "Aubrey Summers", value: "Aubrey Summers" },
        { label: "Audrey Smith", value: "Audrey Smith" },
        { label: "Beth Berkley", value: "Beth Berkley" },
        { label: "Charice Policronis", value: "Charice Policronis" },
        { label: "Dan McDaniel", value: "Dan McDaniel" },
        { label: "Ethan Duncan", value: "Ethan Duncan" },
        { label: "Florence Bentley", value: "Florence Bentley" },
        { label: "Gloria Clauson", value: "Gloria Clauson" },
        { label: "Harry Fisher", value: "Harry Fisher" },
      ]);
    }, 500);
  }

  reset() {
    this.form.patchValue({
      address: [],
      association: [],
      balanceTo: null,
      balanceFrom: null,
      customer: [],
    });
  }
}
