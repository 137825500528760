import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class VendorFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    balanceFrom: [],
    balanceTo: [],
    contact: [],
    name: [],
    propertiesFrom: [],
    propertiesTo: [],
    usersFrom: [],
    usersTo: [],
    vendorType: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.balanceFrom)
        filters.totalBalanceFrom = Number(values.balanceFrom);
      if (values.balanceTo) filters.totalBalanceTo = Number(values.balanceTo);
      if (values.contact) filters.contact = values.contact;
      if (values.name) filters.name = values.name;
      if (values.propertiesFrom)
        filters.countPropertiesFrom = Number(values.propertiesFrom);
      if (values.propertiesTo)
        filters.countPropertiesTo = Number(values.propertiesTo);
      if (values.usersFrom) filters.countUsersFrom = Number(values.usersFrom);
      if (values.usersTo) filters.countUsersTo = Number(values.usersTo);
      if (values.vendorType) filters.type = values.vendorType;

      return filters;
    })
  );

  balanceFrom$ = this.form.get("balanceFrom")?.valueChanges;
  balanceTo$ = this.form.get("balanceTo")?.valueChanges;
  contact$ = this.form.get("contact")?.valueChanges;
  name$ = this.form.get("name")?.valueChanges;
  propertiesFrom$ = this.form.get("propertiesFrom")?.valueChanges;
  propertiesTo$ = this.form.get("propertiesTo")?.valueChanges;
  usersFrom$ = this.form.get("usersFrom")?.valueChanges;
  usersTo$ = this.form.get("usersTo")?.valueChanges;
  vendorType$ = this.form.get("vendorType")?.valueChanges;

  reset() {
    this.form.patchValue({
      balanceFrom: null,
      balanceTo: null,
      contact: null,
      name: null,
      propertiesFrom: null,
      propertiesTo: null,
      usersFrom: null,
      usersTo: null,
      vendorType: null,
    });
  }
}
