import { inject, Injectable } from "@angular/core";
import { environment } from "@environments/environment";

/**
 * @deprecated use LocalStorageApi instead!
 */
@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  #newApi = inject(LocalStorageApi);

  public getUserId(): string {
    return this.#newApi.getItem("user")!.id;
  }

  public clearKey(key: string) {
    this.#newApi.removeItem(key);
  }

  public setKey(key: string, value: string) {
    this.#newApi.setItem(key, value);
  }

  public getKey(key: string): string | null {
    return localStorage.getItem(key);
  }
}

@Injectable({ providedIn: "root" })
export class LocalStorageApi {
  get prefix() {
    return environment.id;
  }

  readonly setItem = <T = any>(key: string, value: T): void => {
    localStorage.setItem(`${this.prefix}-${key}`, JSON.stringify(value));
  };

  readonly getItem = <T = any>(key: string): T | null => {
    const itemString = localStorage.getItem(`${this.prefix}-${key}`);
    return itemString ? JSON.parse(itemString) : null;
  };

  readonly removeItem = (key: string): void => {
    localStorage.removeItem(`${this.prefix}-${key}`);
  };
}
