import { Injectable, inject } from "@angular/core";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";
import { ToastrService } from "ngx-toastr";

// TODO: move to core later!!
@Injectable({ providedIn: "root" })
export class RoamToastrService {
  protected ngx = inject(ToastrService);

  basic(message: string): void {
    this.ngx.success(message, "", ROAM_TOASTR.remove);
  }

  success(message: string): void {
    this.ngx.success(message, "", ROAM_TOASTR.center);
  }

  info(message: string): void {
    this.ngx.info(message, "", ROAM_TOASTR.center);
  }

  warn(message: string): void {
    this.ngx.warning(message, "", ROAM_TOASTR.center);
  }

  danger(message: string): void {
    this.ngx.warning(message, "", ROAM_TOASTR.remove);
  }
}
