import { TableFields } from "@app/shared/class/table-list.class";

export const TASK_TOASTR_MESSAGE = {
  ADD_TASK_SUCCESS: "New task was added!",
  ADD_TASK_FAILED: "Failed to add a new task!",
  SAVE_TASK_SUCCESS: "Task has been saved!",
  SAVE_TASK_FAILED: "Failed to save task!",
  DELETE_TASK_SUCCESS: "The task has been removed!",
  DELETE_TASK_FAILED: "Failed to delete task!",
  UPDATE_TASK_SUCCESS: "The task has been updated!",
  UPDATE_TASK_FAILED: "Failed to update task!",
  DUPLICATE_TASK_SUCCESS: "Task has beed duplicated!",
  DUPLICATE_TASK_FAILED: "Failed to duplicate this task!",
  GET_TASKS_FAILED: "Failed to get task list!",
  UPLOAD_ATTACHMENTS_FAILED: "Failed to upload task attachments!",

  COPIED_TO_CLIPBOARD: "Copied to clipboard!",
} as const;

export const TASK_STATUS_COLORS: Record<string, string> = {
  pending: "purple",
  approved: "dodgerblue",
  "in-progress": "cadetblue",
  review: "orange",
  completed: "mediumseagreen",
  rejected: "crimson",
  default: "#D5C9DF",
} as const;

export const TASK_ACTIVITY_OPTIONS = [
  {
    key: "last-week",
    value: "Active in the last week",
  },
  {
    key: "last-2-weeks",
    value: "Active in the last 2 weeks",
  },
  {
    key: "last-4-weeks",
    value: "Active in the last 4 weeks",
  },
  {
    key: "no-activity",
    value: "Wihout activity in the last 4 weeks",
  },
];

export const TASK_DUE_OPTIONS = [
  {
    key: "last-week",
    value: "No Dates",
  },
  {
    key: "last-2-weeks",
    value: "Overdue",
  },
  {
    key: "last-4-weeks",
    value: "Due in the next day",
  },
  {
    key: "no-activity",
    value: "Due in the next month",
  },
];

export const TASK_DEFAULT_TABLE_FIELDS: TableFields[] = [
  {
    dataKey: "name",
    dataLabel: "Task Name",
    dataType: "custom",
  },
  {
    dataKey: "type",
    dataLabel: "Task Type",
    dataType: "custom",
  },
  {
    dataKey: "property",
    dataLabel: "For",
    dataType: "custom",
  },
  {
    dataKey: "assignedUser",
    dataLabel: "Assignee",
    dataType: "custom",
  },
  {
    dataKey: "due",
    dataLabel: "Due Date",
    dataType: "custom",
  },
  {
    dataKey: "status",
    dataLabel: "Status",
    dataType: "custom",
  },
  {
    dataKey: "action",
    dataLabel: "Action",
    dataType: "custom",
  },
  {
    dataKey: "selection",
    dataLabel: "",
    dataType: "custom",
  },
];

export const TASK_DEFAULT_STATUS = [
  {
    id: 0,
    name: "TO DO",
  },
  {
    id: 1,
    name: "ONGOING",
  },
  {
    id: 2,
    name: "DONE",
  },
];

export const TASK_PRIORITY_COLORS: Record<string, string> = {
  High: "#D40101",
  Medium: "#B5781E",
  Low: "#295AD8",
  "": "black",
};
