import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Injectable, inject } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { BehaviorSubject } from "rxjs";
import { LayoutService } from "./layout.service";

@Injectable({
  providedIn: "root",
})
export class LayoutSidebarService {
  private layoutService = inject(LayoutService);

  drawer?: MatDrawer;

  sidebarComponent$ = new BehaviorSubject<ComponentPortal<any> | null>(null);
  
  hasBackdrop$ = new BehaviorSubject<boolean>(true)

  setBackdrop(hasBackdrop: boolean){
    this.hasBackdrop$.next(hasBackdrop);
  }

  setComponent(component: ComponentType<any>) {
    /* WORKAROUND | aq | prevent error ExpressionChangedAfterItHasBeenCheckedError */
    setTimeout(() => {
      this.sidebarComponent$.next(new ComponentPortal(component));
    });
  }

  removeComponent() {
    // this.sidebarComponent$.next(null);
  }

  open() {
    this.layoutService.setShowChat(false);
    this.drawer && this.drawer.open();
  }

  close() {
    this.drawer && this.drawer.close();
    this.layoutService.setShowChat(true);
  }
}
