import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class AssociationFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    location: [],
    manager: [],
    name: [],
    unitFrom: [],
    unitTo: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.location) filters.location = values.location;
      if (values.manager) filters.managerName = values.manager;
      if (values.name) filters.name = values.name;
      if (values.unitFrom) filters.unitFrom = Number(values.unitFrom);
      if (values.unitTo) filters.unitTo = Number(values.unitTo);

      return filters;
    })
  );

  location$ = this.form.get("location")?.valueChanges;
  manager$ = this.form.get("manager")?.valueChanges;
  name$ = this.form.get("name")?.valueChanges;
  unitFrom$ = this.form.get("unitMin")?.valueChanges;
  unitTo$ = this.form.get("unitMax")?.valueChanges;

  reset() {
    this.form.patchValue({
      location: null,
      manager: null,
      name: null,
      unitTo: null,
      unitFrom: null,
    });
  }
}
