import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class TransferFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    amountFrom: [],
    amountTo: [],
    dateFrom: [],
    dateTo: [],
    fromAccount: [],
    memo: [],
    toAccount: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.amountFrom) filters.amountFrom = Number(values.amountFrom);
      if (values.amountTo) filters.amountTo = Number(values.amountTo);
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.fromAccount) filters.fromAccountName = values.fromAccount;
      if (values.memo) filters.memo = values.memo;
      if (values.toAccount) filters.toAccountName = values.toAccount;

      return filters;
    })
  );

  amountFrom$ = this.form.get("amountFrom")?.valueChanges;
  amountTo$ = this.form.get("amountTo")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  fromAccount$ = this.form.get("fromAccount")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;
  toAccount$ = this.form.get("toAccount")?.valueChanges;

  reset() {
    this.form.patchValue({
      amountFrom: null,
      amountTo: null,
      dateFrom: null,
      dateTo: null,
      fromAccount: null,
      memo: null,
      toAccount: null,
    });
  }
}
