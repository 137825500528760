import { Dialog } from "@angular/cdk/dialog";
import { Overlay } from "@angular/cdk/overlay";
import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ShareLinkDialogData, ShareToEmailDialogData } from "../+ui";
import { ConfirmDialogData } from "../+ui/confirm-dialog/confirm-dialog.component";
import { TaskCardOverlayData, TaskEditorDialogData } from "./task.model";
import { TaskMoveDialogData } from "../task-move-dialog/task-move-dialog.component";

@Injectable({ providedIn: "root" })
export class TaskLazyUi {
  protected dialog = inject(MatDialog);
  protected cdkDialog = inject(Dialog);
  protected overlay = inject(Overlay);

  readonly openTaskEditor = async (data: TaskEditorDialogData) => {
    const factory = await import(
      "../task-editor-dialog/task-editor-dialog.component"
    );
    return this.dialog.open(factory.TaskEditorDialogComponent, {
      backdropClass: "task-dialog-backdrop",
      panelClass: "task-dialog-panel",
      width: "40rem",
      data,
    });
  };

  readonly openTaskMove = async (data: TaskMoveDialogData) => {
    const factory = await import(
      "../task-move-dialog/task-move-dialog.component"
    );
    return this.dialog.open(factory.TaskMoveDialogComponent, {
      backdropClass: "task-dialog-backdrop",
      panelClass: "task-dialog-panel",
      width: "30rem",
      data,
    });
  };

  readonly openConfirmation = async (data: ConfirmDialogData) => {
    return this.dialog.open(
      (await import("../+ui/confirm-dialog/confirm-dialog.component"))
        .ConfirmDialogComponent,
      {
        backdropClass: "task-dialog-backdrop",
        panelClass: "task-dialog-panel",
        width: "700px",
        data: data,
      }
    );
  };

  readonly openShareLink = async (data: ShareLinkDialogData) => {
    return this.dialog.open(
      (await import("../+ui/share-link-dialog/share-link-dialog.component"))
        .ShareLinkDialogComponent,
      {
        backdropClass: "task-dialog-backdrop",
        panelClass: "task-dialog-panel",
        width: "900px",
        data: data,
      }
    );
  };

  readonly openShareToEmail = async (data: ShareToEmailDialogData) => {
    return this.dialog.open(
      (
        await import(
          "../+ui/share-to-email-dialog/share-to-email-dialog.component"
        )
      ).ShareToEmailDialogComponent,
      {
        backdropClass: "task-dialog-backdrop",
        panelClass: "task-dialog-panel",
        width: "900px",
        data,
      }
    );
  };

  readonly openTaskCardOverlay = async (data: TaskCardOverlayData) => {
    const cardRect = data.view.getBoundingClientRect();
    const top = `${cardRect.top}px`;
    const left = `${cardRect.left}px`;
    const positionStrategy = this.overlay
      .position()
      .global()
      .top(top)
      .left(left);
    // TODO: adjust afterClosed and repositioning;
    // const fc = await import("../task-card/task-card-overlay.component");
    // return this.cdkDialog.open(fc.TaskCardOverlayComponent, {
    const fc = await import(
      "../task-editor-popover/task-editor-popover.component"
    );
    return this.cdkDialog.open(fc.TaskEditorPopoverComponent, {
      positionStrategy,
      data,
    });
  };
}
