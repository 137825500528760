import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '@environments/environment';
import { IModules } from "../interfaces";

@Injectable({
    providedIn: 'root'
})
export class ModuleService {
    private apiUrl = environment.apiUrl

    constructor(private http: HttpClient) { }

    getModules(): Observable<IModules> {
        return this.http.get<IModules>(`${this.apiUrl}/modules`, { withCredentials: true })
    }
    
    getMyModules(): Observable<IModules> {
        return this.http.get<IModules>(`${this.apiUrl}/my-modules`, { withCredentials: true })
    }
}
