import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class InspectionFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    association: [],
    dateFrom: [],
    dateTo: [],
    modification: [],
    status: [],
    violation: [],
    workOrder: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.association) filters.association = values.association;
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.modification) filters.modification = values.modification;
      if (values.status) filters.status = values.status;
      if (values.violation) filters.violation = values.violation;
      if (values.workOrder) filters.workOrder = values.workOrder;

      return filters;
    })
  );

  association$ = this.form.get("association")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  modification$ = this.form.get("modification")?.valueChanges;
  status$ = this.form.get("status")?.valueChanges;
  violation$ = this.form.get("violation")?.valueChanges;
  workOrder$ = this.form.get("workOrder")?.valueChanges;

  reset() {
    this.form.patchValue({
      association: null,
      dateFrom: null,
      dateTo: null,
      modification: null,
      status: null,
      violation: null,
      workOrder: null,
    });
  }
}
