import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class VendorCreditFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    creditAmountFrom: [],
    creditAmountTo: [],
    dateFrom: [],
    dateTo: [],
    memo: [],
    refNo: [],
    unappliedFrom: [],
    unappliedTo: [],
    vendor: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.creditAmountFrom)
        filters.creditAmountFrom = Number(values.creditAmountFrom);
      if (values.creditAmountTo)
        filters.creditAmountTo = Number(values.creditAmountTo);
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.memo) filters.memo = values.memo;
      if (values.refNo) filters.referenceNumber = values.refNo;
      if (values.unappliedFrom)
        filters.openAmountFrom = Number(values.unappliedFrom);
      if (values.unappliedTo) filters.openAmountTo = Number(values.unappliedTo);
      if (values.vendor) filters.vendorName = values.vendor;

      return filters;
    })
  );

  creditAmountFrom$ = this.form.get("creditAmountFrom")?.valueChanges;
  creditAmountTo$ = this.form.get("creditAmountTo")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;
  refNo$ = this.form.get("refNo")?.valueChanges;
  unappliedFrom$ = this.form.get("unappliedFrom")?.valueChanges;
  unappliedTo$ = this.form.get("unappliedTo")?.valueChanges;
  vendor$ = this.form.get("vendor")?.valueChanges;

  reset() {
    this.form.patchValue({
      creditAmountFrom: null,
      creditAmountTo: null,
      dateFrom: null,
      dateTo: null,
      memo: null,
      refNo: null,
      unappliedFrom: null,
      unappliedTo: null,
      vendor: null,
    });
  }
}
