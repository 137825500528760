import { computed, Injectable, signal } from "@angular/core";
import { ITaskBoard } from "./task.model";
import { createEntity, toArray } from "./task.utils";
import { Subject } from "rxjs";

interface State {
  entities: Record<string, ITaskBoard>;
}

const initialState: State = {
  entities: {},
};

type PatchBoardFn = (board: ITaskBoard) => ITaskBoard;

const selectIdFn = (model: ITaskBoard) => model.id;

const sortComparerFn = (a: ITaskBoard, b: ITaskBoard) => {
  if (a.order && b.order) {
    return a.order - b.order;
  }
  return a.id.localeCompare(b.id);
};

@Injectable({ providedIn: "root" })
export class TaskBoardStore {
  readonly #state = signal<State>(initialState);
  readonly state = this.#state.asReadonly();
  readonly taskBoards = computed(() => toArray(this.state().entities));

  resetState(): void {
    this.#state.set(initialState);
  }

  readonly setAll = (data: ITaskBoard[]) => {
    return this.#state.update(state => {
      return {
        ...state,
        entities: createEntity({
          sortComparerFn,
          selectIdFn,
          data,
        }),
      };
    });
  };

  readonly addOne = (data: ITaskBoard) => {
    return this.#state.update(state => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [data.id]: data,
        },
      };
    });
  };

  readonly updateOne = (
    id: string,
    changes: Partial<ITaskBoard> | PatchBoardFn
  ) => {
    return this.#state.update(state => {
      const existing = state.entities[id];
      if (!existing) return state;
      changes = typeof changes === "function" ? changes(existing) : changes;
      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...existing,
            ...changes,
          },
        },
      };
    });
  };

  // TODO: This shouldn't be here...
  #reloadNotifier = new Subject<void>();
  readonly reloadNotifier$ = this.#reloadNotifier.asObservable();
  readonly sendReload = () => this.#reloadNotifier.next();
}
