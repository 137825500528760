export const ASYNC_STATE = {
  LOADING: "loading",
  LOADED: "loaded",
  SUBMITTING: "submitting",
  SUBMITTED: "submitted",
  ERROR: "error",
  IDLE: "idle",
} as const;

export type TAsyncState = (typeof ASYNC_STATE)[keyof typeof ASYNC_STATE];
