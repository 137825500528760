import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import mixpanel from 'mixpanel-browser';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LogService {
  public userId: string = '';

  constructor(private router: Router) { }

  mixpanelInit(): void {
    // activate mixpanel tracking
    mixpanel.init(environment.mixpanelToken, {
      track_pageview: true,
      persistence: "localStorage",
      // debug: !environment.production,
    });
    // mixpanel.set_config({'persistence': 'localStorage'})

    this.trackRoutes();
  }
  identify(userId: string): void {
    this.userId = userId;
    // this.writeLog(`identify User: ${this.userId}`);
    // tie actions to user id
    mixpanel.identify(userId);
  }

  track(event: string, data?: any): void {
    // this.writeLog(`track User: ${this.userId} Event: ${event}, Data: ${JSON.stringify(data)}`);
    // log event to mixpanel
    mixpanel.track(event, data);
  }

  // private writeLog(message: string): void {
  //   // log to console
  //   // console.log(message);
  // }

  private trackRoutes(): void {
    // notified on every single page route click
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // this.writeLog(`trackRoutes User: ${this.userId} Page Viewed: ${event.urlAfterRedirects}`)
      this.track('Page Viewed', { path: event.urlAfterRedirects });
    });
  }
}
