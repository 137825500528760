import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ChargeFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    amountFrom: [],
    amountTo: [],
    billedDateFrom: [],
    billedDateTo: [],
    customer: [],
    dueDateFrom: [],
    dueDateTo: [],
    memo: [],
    refNo: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.amountFrom) filters.amountFrom = Number(values.amountFrom);
      if (values.amountTo) filters.amountTo = Number(values.amountTo);
      if (values.billedDateFrom)
        filters.billedDateFrom = new Date(values.billedDateFrom)
          .toJSON()
          .slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.billedDateTo)
        filters.billedDateTo = new Date(values.billedDateTo)
          .toJSON()
          .slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.customer) filters.referenceNumber = values.customer;
      if (values.dueDateFrom)
        filters.dueDateFrom = new Date(values.dueDateFrom)
          .toJSON()
          .slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dueDateTo)
        filters.dueDateTo = new Date(values.dueDateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.memo) filters.memo = values.memo;
      if (values.refNo) filters.referenceNumber = values.refNo;

      return filters;
    })
  );

  amountFrom$ = this.form.get("amountFrom")?.valueChanges;
  amountTo$ = this.form.get("amountTo")?.valueChanges;
  billedDateFrom$ = this.form.get("billedDateFrom")?.valueChanges;
  billedDateTo$ = this.form.get("billedDateTo")?.valueChanges;
  customer$ = this.form.get("customer")?.valueChanges;
  dueDateFrom$ = this.form.get("dueDateFrom")?.valueChanges;
  dueDateTo$ = this.form.get("dueDateTo")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;
  refNo$ = this.form.get("refNo")?.valueChanges;

  reset() {
    this.form.patchValue({
      amountFrom: null,
      amountTo: null,
      billedDateFrom: null,
      billedDateTo: null,
      customer: null,
      dueDateFrom: null,
      dueDateTo: null,
      memo: null,
      refNo: null,
    });
  }
}
