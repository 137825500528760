import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class DepositFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    account: [],
    amountFrom: [],
    amountTo: [],
    dateFrom: [],
    dateTo: [],
    memo: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.account) filters.depositAccountName = values.account;
      if (values.amountFrom)
        filters.depositTotalFrom = Number(values.amountFrom);
      if (values.amountTo) filters.depositTotalTo = Number(values.amountTo);
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.memo) filters.memo = values.memo;

      return filters;
    })
  );

  account$ = this.form.get("account")?.valueChanges;
  amountFrom$ = this.form.get("amountFrom")?.valueChanges;
  amountTo$ = this.form.get("amountTo")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;

  reset() {
    this.form.patchValue({
      account: null,
      amountFrom: null,
      amountTo: null,
      dateFrom: null,
      dateTo: null,
      memo: null,
    });
  }
}
