import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ViolationFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    address: [],
    dateFrom: [],
    dateTo: [],
    nextFollowUp: [],
    recentActivity: [],
    rule: [],
    toEscalate: [],
    statuses: [],
    issue: [],
    ofLetters: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.address) filters.address = values.address;
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.nextFollowUp) filters.nextFollowUp = values.nextFollowUp;
      if (values.rule) filters.ruleName = values.rule;
      if (values.recentActivity)
        filters.referenceNumber = values.recentActivity;
      if (values.toEscalate) filters.toEscalate = values.toEscalate;
      if (values.statuses) filters.statuses = values.statuses;
      if (values.issue) filters.issue = values.issue;
      if (values.ofLetters) filters.ofLetters = values.ofLetters;

      return filters;
    })
  );

  address$ = this.form.get("address")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  nextFollowUp$ = this.form.get("nextFollowUp")?.valueChanges;
  recentActivity$ = this.form.get("recentActivity")?.valueChanges;
  rule$ = this.form.get("rule")?.valueChanges;
  toEscalate$ = this.form.get("toEscalate")?.valueChanges;
  statuses$ = this.form.get("statuses")?.valueChanges;
  issue$ = this.form.get("issue")?.valueChanges;
  ofLetters$ = this.form.get("ofLetters")?.valueChanges;

  reset() {
    this.form.patchValue({
      address: null,
      dateFrom: null,
      dateTo: null,
      nextFollowUp: null,
      recentActivity: null,
      rule: null,
      toEscalate: null,
      statuses: null,
      issue: null,
      ofLetters: null,
    });
  }
}
