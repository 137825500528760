import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  hideHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _showChat$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  readonly showChat$: Observable<boolean> = this._showChat$.asObservable();

  setShowChat(isShow: boolean) {
    this._showChat$.next(isShow);
  }

  readonly headerPinged = signal(false);

  pingHeaderBeam(fadeTime = 1_000) {
    this.headerPinged.set(true);
    setTimeout(() => {
      this.headerPinged.set(false);
    }, fadeTime);
  }
}
