import { ITaskFilter } from "./task.model";

export type SelectIdFn<T> = (obj: T) => any;
export type SortComparerFn<T> = (a: T, b: T) => number;

export function createEntity<T>(options: {
  data: T[];
  selectIdFn: SelectIdFn<T>;
  sortComparerFn?: SortComparerFn<T>;
}): { [key: string]: T } {
  if (options.sortComparerFn) {
    options.data.sort(options.sortComparerFn);
  }
  return options.data.reduce((acc: Record<string, T>, cur) => {
    acc[options.selectIdFn(cur)] = cur;
    return acc;
  }, {});
}

export function toArray<T>(dict: { [key: string]: T }): T[] {
  return Object.values(dict);
}

export function isEmptyObject(value: unknown) {
  if (value === null || value === undefined) return false;
  if (typeof value !== "object") return false;
  const proto = Object.getPrototypeOf(value);
  // consider `Object.create(null)`, commonly used as a safe map
  // before `Map` support, an empty object as well as `{}`
  if (proto !== null && proto !== Object.prototype) return false;
  for (const prop in value) {
    if (Object.hasOwn(value, prop)) {
      return false;
    }
  }
  return true;
}

export const clearTaskFilters = (options: ITaskFilter["filters"] = {}) => {
  if (isEmptyObject(options)) return options;
  for (const [key, value] of Object.entries(options)) {
    if (
      value === undefined ||
      value === null ||
      (typeof value === "string" && "") ||
      (Array.isArray(value) && !value.length)
    ) {
      delete (options as any)[key];
    }
  }
  return options;
};
