import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class FileFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    createdFrom: [],
    createdTo: [],
    folder: [[]],
    type: [[]],
  });

  createdFrom$ = this.form.get("createdFrom")?.valueChanges;
  createdTo$ = this.form.get("createdTo")?.valueChanges;

  folder$ = this.form.get("folder")?.valueChanges;
  folderOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  type$ = this.form.get("type")?.valueChanges;
  typeOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  constructor() {
    /* TODO | aq | remove this debug */
    this.form.valueChanges.subscribe(value => console.log(value));

    this.getOptions();
  }

  private getOptions() {
    /* TODO | aq | integrate with API */
    setTimeout(() => {
      this.typeOptions$.next([
        { label: "Documents", value: "Documents" },
        { label: "Images", value: "Images" },
        { label: "PDF", value: "PDF" },
        { label: "Spreadsheets", value: "Spreadsheets" },
      ]);
    }, 500);
  }

  reset() {
    this.form.patchValue({
      createdFrom: null,
      createdTo: null,
      folder: [],
      type: [],
    });
  }
}
