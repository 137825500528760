import { Signal, signal } from "@angular/core";

export type Reducer<TState, TPayload = any> = (
  state: TState,
  payload?: TPayload,
) => TState;

type Reducers<T> = {
  [K: string]: Reducer<T, any>;
};

type StateSignal<T, TPayload extends Reducers<T>> = Signal<T> & {
  [K in keyof TPayload]: TPayload[K] extends (state: T, payload: infer P) => T
    ? (payload?: P) => void
    : (payload?: Parameters<TPayload[K]>[1]) => void;
};

export function createStateSignal<T, TPayload extends Reducers<T>>(
  initialValue: T,
  reducers: TPayload,
): StateSignal<T, TPayload> {
  const state = signal(initialValue);
  const readonlyState = state.asReadonly();
  for (const [key, reducer] of Object.entries(reducers)) {
    Object.defineProperty(readonlyState, key, {
      value: (payload?: any) => {
        state.set(reducer(readonlyState(), payload));
      },
    });
  }

  return readonlyState as StateSignal<T, TPayload>;
}
