import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class BoardFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    address: [[]],
    appointedFrom: [],
    appointedTo: [],
    name: [[]],
    position: [[]],
  });

  address$ = this.form.get("address")?.valueChanges;
  addressOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  name$ = this.form.get("name")?.valueChanges;
  nameOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  appointedFrom$ = this.form.get("appointedFrom")?.valueChanges;
  appointedTo$ = this.form.get("appointedTo")?.valueChanges;

  position$ = this.form.get("position")?.valueChanges;
  positionOptions$: Subject<{ label: string; value: any }[]> = new Subject();

  constructor() {
    /* TODO | aq | remove this debug */
    this.form.valueChanges.subscribe(value => console.log(value));

    this.getOptions();
  }

  private getOptions() {
    /* TODO | aq | integrate with API */
    setTimeout(() => {
      this.addressOptions$.next([
        { label: "1311 Adcox Square", value: "1311 Adcox Square" },
        { label: "1312 Adcox Square", value: "1312 Adcox Square" },
        { label: "1313 Adcox Square", value: "1313 Adcox Square" },
        { label: "1314 Adcox Square", value: "1314 Adcox Square" },
        { label: "1315 Adcox Square", value: "1315 Adcox Square" },
        { label: "1311 Annslee Circle", value: "1311 Annslee Circle" },
        { label: "1312 Annslee Circle", value: "1312 Annslee Circle" },
        { label: "1313 Annslee Circle", value: "1313 Annslee Circle" },
        { label: "1314 Annslee Circle", value: "1314 Annslee Circle" },
      ]);
      this.nameOptions$.next([
        { label: "Aubrey Summers", value: "Aubrey Summers" },
        { label: "Audrey Smith", value: "Audrey Smith" },
        { label: "Beth Berkley", value: "Beth Berkley" },
        { label: "Charice Policronis", value: "Charice Policronis" },
        { label: "Dan McDaniel", value: "Dan McDaniel" },
        { label: "Ethan Duncan", value: "Ethan Duncan" },
        { label: "Florence Bentley", value: "Florence Bentley" },
        { label: "Gloria Clauson", value: "Gloria Clauson" },
        { label: "Harry Fisher", value: "Harry Fisher" },
      ]);
      this.positionOptions$.next([
        { label: "President", value: "President" },
        { label: "Vice President", value: "Vice President" },
        { label: "Secretary", value: "Secretary" },
        { label: "Treasurer", value: "Treasurer" },
        { label: "Member", value: "Member" },
      ]);
    }, 500);
  }

  reset() {
    this.form.patchValue({
      address: [],
      appointedFrom: null,
      appointedTo: null,
      name: [],
      position: [],
    });
  }
}
