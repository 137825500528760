import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ReceivedPaymentFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    amountFrom: [],
    amountTo: [],
    customer: [],
    dateFrom: [],
    dateTo: [],
    memo: [],
    paymentMethod: [],
    refNo: [],
    unappliedFrom: [],
    unappliedTo: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.amountFrom)
        filters.totalAmountFrom = Number(values.amountFrom);
      if (values.amountTo) filters.totalAmountTo = Number(values.amountTo);
      if (values.customer) filters.customerName = values.customer;
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.memo) filters.memo = values.memo;
      if (values.paymentMethod)
        filters.paymentMethodName = values.paymentMethod;
      if (values.refNo) filters.referenceNumber = values.refNo;
      if (values.unappliedFrom)
        filters.unusedPaymentsFrom = Number(values.unappliedFrom);
      if (values.unappliedTo)
        filters.unusedPaymentsTo = Number(values.unappliedTo);

      return filters;
    })
  );

  amountFrom$ = this.form.get("amountFrom")?.valueChanges;
  amountTo$ = this.form.get("amountTo")?.valueChanges;
  customer$ = this.form.get("customer")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;
  paymentMethod$ = this.form.get("paymentMethod")?.valueChanges;
  refNo$ = this.form.get("refNo")?.valueChanges;
  unappliedFrom$ = this.form.get("unappliedFrom")?.valueChanges;
  unappliedTo$ = this.form.get("unappliedTo")?.valueChanges;

  reset() {
    this.form.patchValue({
      amountFrom: null,
      amountTo: null,
      customer: null,
      dateFrom: null,
      dateTo: null,
      memo: null,
      paymentMethod: null,
      refNo: null,
      unappliedFrom: null,
      unappliedTo: null,
    });
  }
}
